.footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    background-color: black;
  }
  
  .section {
    flex-basis: 30%;
    margin-bottom: 20px;
  }
  
  .section h4 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .section p {
    margin: 0;
  }
  
  .section form {
    display: grid;
    gap: 10px;
  }
  
  .section form .form-row {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
  }

  .btn{
    height: 40px;
    width: fit-content;
    padding: 20px;
    background-color: red;
    color: white;
    font-family: 'roboto';
    font-size: 18px;
    font-weight: 600;
    border: 0px !important;
    cursor: pointer;
  }

  
  .section form input,
  .section form button {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
  }

  .head{
    font-family: "roboto";
    font-size: 20px;
    color: #ffffff;
    font-family: bold;
    font-style: normal;
  }

  .para{
    font-family: "roboto";
    font-size: 18px;
    color: #ffffff;
    font-family: 400;
    font-style: normal;
  }

  .para1{
    font-family: "roboto";
    font-size: 18px;
    color: black;
    font-family: 600;
    font-style: normal;
  }
  
  @media (max-width: 768px) {
    .section {
      flex-basis: 100%;
    }
  }
  