.drawer-large {
    width: 300px;
  }
  
  .drawer-small {
    width: 80vw;
    max-width: 300px;
  }

  .inp-sty{
    width: 300px;
    border: 2px solid #ffffff;
    height: 30px;
    border-radius: 5px;
    margin-top: 18px;
  }

  .head-sty{
    font-family: "roboto";
    color: #696868;
    font-size: 20px !important;
    font-weight: 900 !important;
  }

  .css-10hburv-MuiTypography-root{
    font-family: "roboto";
    color: #696868;
    font-size: 20px !important;
    font-weight: 900 !important;
  }

  /* .MuiToolbar-root{
    width: 300px !important;
  } */