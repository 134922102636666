.header {
    position: sticky;
    top: 0;
    background-color: #f8f8f8;
    /* padding: 20px; */
    z-index: 100;
    box-shadow: 50 2px 4px rgba(255, 255, 255, 0.5);
  }

  .img-trans{
    position: 'absolute';
    top: '50%';
    left: '50%';
    transform: 'translate(-50%, -50%)';
    color: 'white';
    font-size: '24px';
    font-weight: 'bold';
    text-shadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
}

.inp-sty-2{
    font-family: "roboto";
    font-size: 68px;
    font-weight: 900;
    color: #ffffff;
    font-style: normal;
    line-height: 113.5%;
}

.para-sty{
    font-family: "roboto";
    font-size: 42px;
    font-weight: 600;
    color: #ffffff;
    font-style: normal;
}

.column-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

.inp-sty-3{
    font-family: "roboto";
    font-size: 40px;
    font-weight: 800;
    color: #696868;
    font-style: normal;
    line-height: 113.5%;
    margin: 0px;
    margin-top:2%;
    margin-bottom: 2%;
}

.inp-sty-4{
    font-family: "roboto";
    font-size: 40px;
    font-weight: 800;
    color: #696868;
    font-style: normal;
    line-height: 113.5%;
    margin: 0px;
    margin-bottom: 1%;
}

.para-sty-2{
    font-family: "roboto";
    font-size: 24px;
    font-weight: 600;
    color: black;
    font-style: normal;
    line-height: 115.3%;
    margin: 0px;
}

.para-sty-gr{
  font-family: "roboto";
  font-size: 28px;
  font-weight: 600;
  color: #696868;
  font-style: normal;
  line-height: 115.3%;
  margin: 0px;
}

.glob-clr{
    color: red !important;
}

.shadow-div {
    box-shadow: 0 2px 4px rgba(0.2, 0.2, 0.2, 0.2);
    background-color: #d2d6d2;
    padding: 3%;
  }

  .shadow-div2 {
    box-shadow: 0 2px 4px rgba(0.2, 0.2, 0.2, 0.2);
    background-color: #d2d6d2;
  }

.glob-mar-tp{
    margin-top: 2.5% !important;
}

.glob-mar-bt{
    margin-bottom: 2% !important;
}

.glob-marg-lef{
    margin-left: 1% !important;
}

.glob-marg-rig{
    margin-right: 1% !important;
}

.container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .content {
    flex: 1 1 300px;
    padding: 20px;
    padding-top: 0px !important;
  }
  
  .image {
    flex: 1 1 300px;
    text-align: center;
  }

  .text-align{
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
  }
  
  .image img {
    /* max-width: 100%; */
    max-height: 600px;
    width: fit-content;
    height: auto;
    object-fit: contain;
  }
  
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
    }
  }
  

@media (max-width: 600px) {
    .inp-sty-2{
        font-family: "roboto";
        font-size: 26px;
        font-weight: 800;
        color: #ffffff;
        font-style: normal;
        line-height: 113.5%;
    }

    .para-sty{
        font-family: "roboto";
        font-size: 18px;
        font-weight: 600;
        color: #ffffff;
        font-style: normal;
    }

    .inp-sty-3{
        font-family: "roboto";
        font-size: 28px;
        font-weight: 800;
        color: #696868;
        font-style: normal;
        line-height: 113.5%;
        margin: 0px;
        margin-top:3%;
        margin-bottom: 1%;
    }
    
    .para-sty-2{
        font-family: "roboto";
        font-size: 16px;
        font-weight: 600;
        color: #696868;
        font-style: normal;
        line-height: 115.3%;
        margin: 0px;
    }
}